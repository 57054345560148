<template>
  <b-card v-if="data">
    <b-card-title class="mb-1">
      Ventes Par catégorie
    </b-card-title>
    <b-card-sub-title class="mb-2">
      <!-- Spending on various categories -->
    </b-card-sub-title>

    <vue-apex-charts 
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="data.data" 
    />
      <!-- :options="data.cat"
      :series="data.data" -->
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,      
     donutChart: {
    series: [],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
         '#ffe700',
         '#00d4bd',
         '#826bf8',
         '#FFA1A1',
       
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return ``
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Operational',
                formatter() {
                  return '31%'
                },
              },
            },
          },
        },
      },
      labels: [],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },
    }
  },
    props: {
    data: {
     type: Object,
      default: null,
    },
  },
    mounted: function () {
       this.$http.get(`${config.API_BASE_URL}/api/auth/getVenteCat`).then(res => { 
                
               this.donutChart={
                   series: res.data.VenteCat.data,
                  chartOptions: {

                   plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return ``
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: '',
                formatter() {
                  return ''
                },
              },
            },
          },
        },
      },
      // labels: [ "Non Catégorisé", "Technologie", "Autre", "Maison" ],
              labels: res.data.VenteCat.cat,
     
    }, };
         });
    
    }
}
</script>
