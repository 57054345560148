<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>Votre Profite 🎉 </h5>
   <template v-if="data!=null ">
    <h2 class="mb-75 mt-2 pt-50">
      <b-link>{{ TwoDigits(data) }}  Dhs</b-link>
    </h2>
   </template>
   <template v-else-if="data==0">
    <h2 class="mb-75 mt-2 pt-50">
      <b-link>0  Dhs</b-link>
    </h2>
   </template>
    <template v-else>
    <h2 class="mb-75 mt-2 pt-50">
      <b-link>0  Dhs</b-link>
    </h2>
   </template>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      View Sales
    </b-button> -->
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      // type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
      TwoDigits(val){
        // console.log(typeof(val));
            return parseFloat(val).toFixed(2);
            
      },
  },
}
</script>
