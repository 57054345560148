<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Statistiques des ventes</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart  
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      dd:['20','25'],
    
    dataChart: {
      labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov','Déc'],
      datasets: [
        {
          
          // data: [100, 520, 10, 205, 125, 85, 55, 87, 127, 150, 230, 280], 
          data:[],
          backgroundColor: '#28dac6',
          borderColor: 'transparent',
        },
      ],
    },
   
       
    }
  },

   props: {
    data2: {
      // type: Object,
      default: () => {},
    },
  },
   mounted() {
    this.$http.get(`${config.API_BASE_URL}/api/auth/getVenteCat`).then(res => { 
       });
   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
