<template>
  <b-card title="Statistiques des ventes">
    <div class="d-flex justify-content-between flex-wrap">
      <!-- <div class="mb-1 mb-sm-0">
        <span class="text-muted">Statistiques des ventes</span>
      </div> -->
      <!-- <div class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder ">
          $ 183,382
        </h1>
        <div class="pt-25 ml-75">
          <b-badge variant="light-secondary">
            <feather-icon
              icon="ArrowDownIcon"
              size="12"
              class="text-danger align-middle"
            />
            <span class="font-weight-bolder align-middle"> 24%</span>
          </b-badge>
        </div>
      </div> -->
    </div>

    <!-- echart -->
    <app-echart-line 
    :option-data="option" 
    :data="data" />
    <!-- [ 0,0,0,0,0,0,0, 0,0,55,0,0] -->

  </b-card>
</template>

<script>
import config from '@/config'
import { BCard, BBadge } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
  },
   props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      option: {
        responsive:true,
        xAxisData: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov','Déc'],
        series: ['290', '200', '210', '190', '250', '220', '80', '90', '200', '150', '160', '100', '140', '100', '30'],
      },
    }
  },
}
</script>
