<template>
  <div>
  <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <section id="dashboard-ecommerce" v-else>
    <b-row class="match-height">
      <!-- {{data.transactionData}} -->
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="dashdata.Profite" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="dashdata.statistics" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
         <apex-donut-chart :data="dashdata.VenteCat" />
          <!-- <echart-doughnut /> -->
        <!-- <b-row class="match-height">
          
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row> -->
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="dashdata.ventes" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <ecommerce-company-table :table-data="dashdata.topProduct" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="6"
        md="6"
      >
          <ecommerce-transactions :data="dashdata.topClient" />      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <b-col
        lg="6"
        md="6"
      >
     <apex-bar-chart />
      </b-col>

      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col> -->
     

     
    </b-row>
  </section>
  </div>
  
</template>

<script>
import config from '@/config'
import { BRow, BCol,BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import ApexDonutChart from './Chart/ApexDonutChart.vue'
import EchartDoughnut from './Chart/EchartDoughnut.vue'
import ApexBarChart from './Chart/ApexBarChart.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

// <b-col md="6">
    //   <apex-donut-chart />
    // </b-col>

export default {
  components: {
    BRow,
    BCol,BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, 
    VueApexCharts,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    // ChartjsBarChart
    ApexDonutChart,
    EchartDoughnut,
    ApexBarChart
  },
  data() {
    return {
      loading : false,
      data: {},
      dashdata:{},
      chartData: {},
      permission:[],
    
    
    }
  },
  created() {
    this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getVenteCat`).then(res => { this.dashdata = res.data ,this.loading = false});
        this.$http.get('/card/card-analytics/sessions-device').then(res => { this.chartData = res.data })

    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        const userData = getUserData()
        // this.data.congratulations.name =  localStorage.setItem('userData',userData.user.name.split(' ')[0] || userData.user.prenom)
      })
  },
  // methods:{
  //   redirect(){
  //      this.$router.push({ name: 'misc-not-authorized' })
  //   }
  // },
   mounted: function () {
              // console.log(!JSON.parse(localStorage.getItem('access')).includes('Dashbaord') && !JSON.parse(localStorage.getItem('access')).includes('Admin'))

      if(!JSON.parse(localStorage.getItem('access')).includes('Dashbaord') && !JSON.parse(localStorage.getItem('access')).includes('Admin')) {
           this.$router.push({ name: 'misc-not-authorized' })
      //     console.log(JSON.parse(localStorage.getItem('access')).includes('Dashbaord'))
      }
     },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
