<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table caption-top sticky-header  head-variant="light"
    striped hover
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
    <template #table-caption><center>Les produits les plus vendus</center></template>

      <!-- company -->
      <template #cell(NomSociete)="data">
        <div class="d-flex align-items-center">
           <b-avatar
          :src="`${path}${data.item.logo}`"
            class="mx-1"
            size="40"

          />
          <!-- <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              :src="`${config.API_BASE_URL}/storage/${data.item.logo}`"
              alt="avatar img"
            />
            </b-avatar> -->
          <div>
            <div class="font-weight-bolder">
              {{ data.item.NomSociete }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.Ice }}
            </div>
          </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(Nom)="data">
        <div class="d-flex align-items-center">
         
          <span>{{ data.item.Nom }}</span>
        </div>
      </template>

      <!-- views -->
      <template #cell(category)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.category }}</span>
          <!-- <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span> -->
        </div>
      </template>

      <!-- revenue -->
      <template #cell(nbProd)="data">
        {{ data.item.nbProd+' Commandes' }}
      </template>

      <!-- <template #cell(sales)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.sales+'%' }}</span>
          <feather-icon
            :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
            :class="data.item.loss ? 'text-danger':'text-success'"
          />
        </div>
      </template> -->
    </b-table>
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
        path:config.path,
        fields: [
          { key: 'NomSociete', label: 'Fournisseur°' },
          { key: 'Nom', label: 'Nom Produit' },
          { key: 'category', label: 'Catégorie' },
          { key: 'nbProd', label: 'Ventes' },
          // { key: 'sales', label: 'SALES' },
        ],
      
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
