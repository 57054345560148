<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Top 5 Clients</b-card-title>

     
    </b-card-header>

    <b-card-body>
      <div
        v-for="transaction in data"
        :key="transaction.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-primary"
            >
              <feather-icon
                size="18"
                icon="UserIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.NomSociete }} - {{transaction.nbClient}}<span><small> Commandes</small></span>
            </h6>
            <small>{{ 'N° '+transaction.NumClient }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-success"
         
        >
          {{ transaction.nbVentes +' Unités' }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
