<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <!-- <b-card-sub-title class="mb-25">
          Balance
        </b-card-sub-title> -->
        <b-card-title class="font-weight-bolder">
           Les Top 5 Catégories
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

    
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  data() {
    return {
      apexChatData,
      dataChart:{},
       barChart: {
    series: [
      {
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
    }
  },
   mounted: function () {
      this.$http.get(`${config.API_BASE_URL}/api/auth/topCategory`).then(res =>{
         this.dataChart=res.data,
         this.barChart={
               series: [
      {
        data: res.data.nb,
      },
    ],
    chartOptions:{
       xaxis: {
        categories: res.data.cat,
      },
    }
                     };
       })
   }
}
</script>
